<template>
  <div class="healthIQ">
    <!-- 卡片视图区域 -->
    <el-card style="min-width: 1000px">
      <el-row>
        <el-col class="tabbar">
          <div class="">
            <el-input size="medium" class="demo-input-label" placeholder="请输入公司名称" maxlength="10" v-model="params.companyName" clearable>
            </el-input>
            <el-input size="medium" class="demo-input-label" placeholder="请输入公司编码" maxlength="10" v-model="params.companyNo" clearable>
            </el-input>
            <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div class="main right">
      <el-table :data="records" border style="width: 98%" fixed :height="tableHeight">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="companyName" label="公司名称" width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="name" label="订单名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="id" label="订单编号" width="150" :show-overflow-tooltip="true"></el-table-column>

        <el-table-column prop="startTime" label="开始时间" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="状态" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0">未开始</el-tag>
            <el-tag v-if="scope.row.status == 1">进行中</el-tag>
            <el-tag v-if="scope.row.status == 2">已结束</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="320">
          <template slot-scope="scope">
            <el-button v-if="
                scope.row.sassServiceDictNo == 1009 && scope.row.status !== 2
              " type="primary" icon="el-icon-edit" size="mini" @click="topicClick(scope.row)">选题</el-button>
            <el-button v-if="scope.row.sassServiceDictNo == 1009" type="primary" icon="el-icon-search" size="mini" @click="topicGetClick(scope.row)">查看选题</el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="examineInfoClick(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <template slot="opt"> > </template>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>
    <!-- 修改用户的对话框 -->
    <el-dialog title="修改用户" :visible.sync="setRoleDialogVisible" width="50%" @close="editDialogClosed">
      <el-form :model="saveInfoParams" ref="editFormRef" label-width="70px">
        <el-form-item label="公司编号">
          <el-select v-model="saveInfoParams.companyNo" placeholder="请选择">
            <el-option v-for="item in companyList" :key="item.companyNo" :label="item.companyName" :value="item.companyNo">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="timevalue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerBeginDateBefore" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="selectTime">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="节点工单">
          <el-input v-model="saveInfoParams.sassServiceDictNo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInfo">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <el-dialog title="订单信息" :visible.sync="isExamineInfo" width="50%" @close="isExamineInfo = false">
      <el-form :model="examineInfo" label-width="70px">
        <el-form-item label="开始时间">
          <span>{{ examineInfo.createTime }}</span>
        </el-form-item>

        <el-form-item label="题目数量">
          <span>{{ examineInfo.questionNum }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isExamineInfo = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "@/components/common/pagination/Index.vue";
import Cookies from "js-cookie";
export default {
  name: "AdminDemoIndex",
  components: {
    pagination,
  },
  data() {
    return {
      tableHeight: window.innerHeight - 350,
      params: { pageNum: 1, pageSize: 10, companyName: "", companyNo: "" },
      records: [],
      total: 0,
      isIndeterminate: false,
      timevalue: "",
      saveInfoParams: {
        companyNo: "",
        sassServiceDictNo: "123",
        createUser: 1,
        endTime: "",
        nodeOrder: {},
        startTime: "",
        updateUser: 1,
      },

      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      minDate: "",
      maxDate: "",
      pickerBeginDateBefore: {
        onPick: ({ maxDate, minDate }) => {
          this.saveInfoParams.startTime = minDate;
          this.saveInfoParams.endTime = maxDate;
        },
      },
      examineInfo: {},
      isExamineInfo: false,
    };
  },

  created() {
    this.findList();
  },
  methods: {
    async examineInfoClick(item) {
      this.isExamineInfo = true;
      const res = await this.sassOrderHealthIQInfo(item.id);
      this.examineInfo = res;
    },

    async sassOrderHealthIQInfo(ID) {
      const res = await this.$API.sassOrderHealthIQInfo({ id: ID });
      if (res.status == 200) {
        return res.data;
      }
    },
    async topicClick(item) {
      const res = await this.sassOrderHealthIQInfo(item.id);
      if (res) {
        item["questionNum"] = res.questionNum;
        Cookies.set("ORDER", JSON.stringify(item));
        this.$router.push({
          path: "/business/order/companyOrder/Topic",
        });
      }
    },
    async topicGetClick(item) {
      const res = await this.sassOrderHealthIQInfo(item.id);
      item["questionNum"] = res.questionNum;
      Cookies.set("ORDER", JSON.stringify(item));
      this.$router.push({
        path: "/business/order/companyOrder/getTopic",
      });
    },

    selectTime() {
      this.saveInfoParams.startTime = `${this.timevalue[0]} 00:00:00`;
      this.saveInfoParams.endTime = `${this.timevalue[1]} 23:59:59`;
    },
    setRoleDialogClosed() {},
    async saveInfo() {
      const params = JSON.parse(JSON.stringify(this.saveInfoParams));
      const res = await this.$API.sassOrderSave(params);
      if (res.status == 200) {
        this.setRoleDialogVisible = false;
        this.$message.success(res.msg);
        this.params.pageNum = 1;
        this.params.companyName = "";
        this.findList();
      }
    },
    async removeById(item) {
      const { id } = item;
      let params = {
        id,
      };
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      const res = await this.$API.sassOrderRemove(params);

      if (res.status !== 200) {
        return this.$message.error("删除失败！");
      }
      this.$message.success("删除成功！");
      this.findList();
    },
    async addInfo(item = {}) {
      const companyList = await this.findcompanyList();
      if (companyList.length == 0) {
        return this.$message.info("请先添加公司");
      }
      this.companyList = companyList;
      console.log(companyList[0]);
      this.setRoleDialogVisible = true;
      if (item) {
        this.saveInfoParams = item;
        if (item.startTime) {
          this.timevalue = [
            item.startTime.split(" ")[0],
            item.endTime.split(" ")[0],
          ];
        }
      }
    },
    searchClick() {
      this.params.pageNum = 1;
      this.findList();
    },
    handleSizeChange(newSize) {
      this.params.pageSize = newSize;
      this.findList();
    },
    handleCurrentChange(newPage) {
      this.params.pageNum = newPage;
      this.findList();
    },
    handleCheckAllChange() {},
    async findList() {
      const res = await this.$API.sassOrderQuery(this.params);
      if (res.status == 200) {
        const { records, total } = res.data;
        this.records = records;
        this.total = +total;
      }
    },
    async findcompanyList() {
      const res = await this.$API.sCompanyQuery();
      if (res.status == 200) {
        console.log(res.data);
        return res.data;
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

.tabbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}

.el-tag {
  margin: 7px;
}
</style>
